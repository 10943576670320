import React from 'react';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import Header from '../components/Header';
import Button from '../components/Button';
import { Link } from 'gatsby';
import { ModalRoutingContext } from 'gatsby-plugin-modal-routing';

import css from '../css/pages/newsletter.module.css';
import closeIcon from '../images/close.svg';

class NewsletterPage extends React.Component {
  constructor() {
    super();
  }

  render() {
    return (
      <ModalRoutingContext>
        {({ modal, closeTo }) => (
          <div>
            {modal ? (
              <div>
                <div className="closeModal">
                  <Link
                    to={closeTo}
                    state={{
                      noScroll: true
                    }}
                  >
                    <img src={closeIcon} />
                  </Link>
                </div>
                <div className={css.boxModal}>
                  <div className="header ta-center mb-2">
                    <h1 className={css.title}>Sign up for the newsletter</h1>
                    <p className={css.prompt}>
                      Once a month we send out a carefully curated selection of projects, concepts
                      and initiatives at the intersection of nature, technology and culture.{' '}
                    </p>
                  </div>

                  <form
                    action="https://buttondown.email/api/emails/embed-subscribe/cyberbiomes"
                    method="post"
                    target="popupwindow"
                    onsubmit="window.open('https://buttondown.email/cyberbiomes', 'popupwindow')"
                    className="embeddable-buttondown-form"
                  >
                    <label for="bd-email" className="sr-only">
                      Enter your email
                    </label>
                    <input type="email" name="email" id="bd-email" placeholder="your email" />
                    <input type="hidden" value="1" name="embed" />
                    <div className="mt-1 ta-center">
                      <Button type="submit" value="Subscribe" />
                    </div>
                  </form>
                </div>
              </div>
            ) : (
              <Layout>
                <SEO title="Cyberbiomes" />
                <Header />
                <section className="raised">
                  <div className="slide">
                    <div className={css.box}>
                      <div className="header ta-center mb-2">
                        <h1 className={css.title}>Sign up for the newsletter</h1>
                        <p className={css.prompt}>
                          Once a month we send out a carefully curated selection of projects,
                          concepts and initiatives at the intersection of nature, technology and
                          culture.{' '}
                        </p>
                      </div>

                      <form
                        action="https://buttondown.email/api/emails/embed-subscribe/cyberbiomes"
                        method="post"
                        target="popupwindow"
                        onsubmit="window.open('https://buttondown.email/cyberbiomes', 'popupwindow')"
                        className="embeddable-buttondown-form"
                      >
                        <label for="bd-email" className="sr-only">
                          Enter your email
                        </label>
                        <input type="email" name="email" id="bd-email" placeholder="your email" />
                        <input type="hidden" value="1" name="embed" />
                        <div className="mt-1 ta-center">
                          <Button type="submit" value="Subscribe" />
                        </div>
                      </form>
                    </div>
                  </div>
                </section>
              </Layout>
            )}
          </div>
        )}
      </ModalRoutingContext>
    );
  }
}

export default NewsletterPage;
